const DEFAULT_CONTEXT = 'https://schema.org'
const DEFAULT_TYPE = 'FAQPage'
const DEFAULT_MAIN_ENTITY = []

export default class StructuredData {
  constructor() {
    this.context = DEFAULT_CONTEXT
    this.type = DEFAULT_TYPE
    this.mainEntity = DEFAULT_MAIN_ENTITY
  }

  get() {
    if (this.hasData()) {
      return {
        '@context': this.context,
        '@type': this.type,
        mainEntity: this.mainEntity
      }
    }

    return undefined
  }

  hasData() {
    return this.mainEntity.length > 0
  }

  reset() {
    this.context = DEFAULT_CONTEXT
    this.type = DEFAULT_TYPE
    this.mainEntity = DEFAULT_MAIN_ENTITY
  }
}
