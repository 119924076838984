import config from '@brand'

class Brand {
  constructor(config) {
    for (let [key, value] of Object.entries(config)) {
      this[key] = value
    }
  }

  isFonic() {
    return this.code === 'fonic'
  }

  isFonicMobile() {
    return this.code === 'fonic-mobile'
  }
}

const brand = new Brand(config)

const plugIn = {
  install(app) {
    app.brand = brand
    app.config.globalProperties.$brand = brand
  }
}

export { brand, plugIn }
