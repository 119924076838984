import StructuredData from 'src/lib/structured_data.js'

const brand =
  import.meta.env.VITE_BRAND_CODE === 'LIDL' ? 'fonic-mobile' : 'fonic'
const structuredDataForBrand = import(`../../seo/${brand}/structured_data.json`)

const structuredDataPlugIn = {
  install(app, { router }) {
    const structuredData = new StructuredData()

    router.beforeEach((to, from, next) => {
      // Only continue once the data is definitely loaded, otherwise the structured data will not be
      // inserted on initial page load
      structuredDataForBrand.then(data => {
        structuredData.reset()

        const routeStructuredData = data.default[to.fullPath] ?? {}

        if (routeStructuredData.type) {
          structuredData.type = routeStructuredData.type
        }

        if (routeStructuredData.mainEntity) {
          structuredData.mainEntity = routeStructuredData.mainEntity
        }

        to.meta.structuredData = structuredData.get()

        next()
      })
    })
  }
}

export { structuredDataPlugIn }
