// create window object and updateABTest function as early as possible
import { bus } from 'src/plugins/bus'

window.a_b_test = window.a_b_test || {}

if (!window.updateABTest) {
  window.updateABTest = (name, value) => {
    if (name && value) {
      window.a_b_test[name] = value
    }

    bus.emit('update-a-b-test')
  }
}
