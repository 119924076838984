import { reactive } from 'vue'

class Breakpoint {
  constructor() {
    this.state = reactive({ breakpoint: undefined })
    window.addEventListener('resize', () => this.computeBreakpoint())
  }

  computeBreakpoint() {
    this.state.breakpoint = window
      .getComputedStyle(document.body, ':before')
      .content.replace(/"/g, '')
    if (!this.state.breakpoint || this.state.breakpoint.length === 0) {
      this.state.breakpoint = 'mobile'
    }
  }

  getBreakpoint() {
    if (!this.state.breakpoint || this.state.breakpoint === 'none') {
      this.computeBreakpoint()
    }

    return this.state.breakpoint
  }

  mobile() {
    return this.getBreakpoint() === 'mobile'
  }

  tablet() {
    return this.getBreakpoint() === 'tablet'
  }

  desktop() {
    return this.getBreakpoint() === 'desktop'
  }

  threeColumns() {
    return this.getBreakpoint() === 'three-columns'
  }

  wide() {
    return this.getBreakpoint() === 'wide'
  }

  upToTablet() {
    return this.mobile()
  }

  upToDesktop() {
    return this.upToTablet() || this.tablet()
  }

  upToThreeColumns() {
    return this.upToDesktop() || this.desktop()
  }

  upToWide() {
    return this.upToThreeColumns() || this.threeColumns()
  }

  fromWide() {
    return this.wide()
  }

  fromThreeColumns() {
    return this.threeColumns() || this.wide()
  }

  fromDesktop() {
    return this.desktop() || this.fromThreeColumns()
  }

  fromTablet() {
    return this.tablet() || this.fromDesktop()
  }
}

export const breakpointPlugIn = {
  install(app) {
    app.config.globalProperties.$breakpoint = new Breakpoint()
  }
}
